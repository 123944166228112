import React from "react"
import SEO from "../components/seo"
import { graphql } from 'gatsby'
import PostList from '../components/postList'
import { Link } from "gatsby"
import styles from '../styles/index.module.css'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'


const CuisineCollectionPage = ({data, pageContext, location}) => {
    const currentPage = pageContext.currentPage
    const cuisineSlug = pageContext.id.replace(/ /g, '_').toLowerCase()
    const previousPageLink = currentPage != 2? "/cuisine/"+cuisineSlug+"/page/" + (currentPage - 1): "/cuisine/"+cuisineSlug
    const nextPageLink = "/cuisine/"+cuisineSlug+"/page/" + (currentPage + 1)
    return(
        <div>
            <SEO title={pageContext.id} canonicalPath={location.pathname}/>
            <h1 className={styles.collectionHeader}>{pageContext.id}</h1>
            <PostList data={data.allStrapiPosts.edges}/>
            <div className={styles.pageLinkContainer}>
            { currentPage != 1? <div className={styles.prevPage}>
                <Link to={previousPageLink}>
                  <AiOutlineLeft style={{paddingTop: '5px'}} size='1em'/><span>Previous page</span>
                </Link>
              </div>: ''
              }
              { pageContext.numPages !== currentPage?
                  <div className={styles.nextPage}>
                    <Link to={nextPageLink}>
                      <span>Next page</span><AiOutlineRight style={{paddingTop: '5px'}} size='1em'/>
                    </Link>
                  </div>:
                    ''
              }   
            </div>
        </div>
    )
}

export const query = graphql`
  query cuisinePaginatedQuery($skip: Int!, $limit: Int!, $id: String!) {
    allStrapiPosts(
      sort: { fields: [created_at], order: DESC }
      filter: {recipe_cuisine: {name: {eq: $id}}}
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          title
          description
          websiteURL
          created_at
          youtubeURL
          recipe_category {
            name
          }
          recipe_cuisine {
            name
          }
          featureImage {
            childImageSharp {
              fluid{
                src
                srcSet
                presentationWidth
                presentationHeight
                base64
                aspectRatio
              }
            }
          }
        }
      }
    }
  }
`

export default CuisineCollectionPage
